import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import { Welcome } from './UI/Welcome';
import { SizeContext } from './context';
import { Presentation } from './UI/Presentation';

export const App: React.FC = ({}) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showPresentation, setShowPresentation] = useState(false);

  const context = useContext(SizeContext);
  
  useEffect(()=> {
    getSize();
    window.addEventListener('resize', handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.screen.orientation.addEventListener("change", handleResize);

  },[])

  const handleResize = () => {
    getSize();
  }

  const getSize = () => {
    var ratio = 2048.0 / 1080.0;
    var windowHeight = Math.min(window.screen.height, window.innerHeight);
    var windowWidth = Math.min(window.screen.width, window.innerWidth);

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (iOSSafari) {
      windowHeight = window.innerHeight;
      windowWidth = window.innerWidth;
    }

    var webWidth = windowWidth;
    var webHeight = windowWidth / ratio;

    if (webHeight > windowHeight) {
        webHeight = windowHeight - 2;
        webWidth = webHeight * ratio;
    }

    context.width = webWidth;
    context.height = webHeight
  
    var element = document.getElementById("app");
    if (element) {
      element.style.width = webWidth + "px"; 
      element.style.height = webHeight + "px"; 
      element.style.left = (windowWidth - webWidth)/2 + "px"; 
    }
  }

  return (
    <div className="App" id="app">
      {showWelcome && 
        <Welcome onEnd={()=>{setShowPresentation(true); setShowWelcome(false);}}/>
      }

      {showPresentation &&
      <Presentation/>
      }
    </div>
  );
}

export default App;
