import React, {useEffect, useState, useContext} from 'react';

import { ImageButton } from './Components/ImageButton';
import ReactPlayer from 'react-player'
import './Welcome.css';

interface WelcomeProps{
    onEnd: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({onEnd}: WelcomeProps) => {
    const [displayButton, setDisplayButton] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);

    const [buttonWidth, setButtonWidth] = useState(10);
    const [buttonHeight, setButtonHeight] = useState(10);


    const onVideoEnd = () => {
        setDisplayButton(true);
    }

    useEffect(()=> {
        getSize();
        window.addEventListener('resize', getSize);
        window.addEventListener("orientationchange", getSize);
        window.screen.orientation.addEventListener("change", getSize);
    
        // 👇️ remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', getSize);
          window.removeEventListener('orientationchange', getSize);
          window.screen.orientation.removeEventListener('change', getSize);
        };
      },[])

      const getSize = () => {
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari) {
            windowHeight = window.innerHeight;
            windowWidth = window.innerWidth;
        }

        var imageHeight = 1080;
        var imageWidth = 2048;
        setButtonHeight(161 * windowHeight / imageHeight);
        setButtonWidth(550 * windowWidth / imageWidth);

    }

    const startPlayingVideo = () => {
        let videoPlayer = (document.getElementsByTagName('video') as HTMLCollectionOf<HTMLVideoElement> | null);
        if (videoPlayer) {
            videoPlayer[0].play();
        }
    }
    
    return (
        <>
         {!playVideo &&
        <div className='Start'>
            <div style={{position: 'relative', width: buttonWidth, height: buttonHeight, margin: "auto", top: "50%"}}>
                <ImageButton
                image='images/play.png'
                    onClick={() => {
                        setPlayVideo(true);
                        setTimeout(startPlayingVideo, 200);
                    }}
                />
        </div>
        </div>
        }
        { playVideo && 
            <ReactPlayer 
                playing={false}
                url={"videos/video0.mp4"}
                width='100%'
                height='100%'
                loop={false}
                muted={false}
                config={{ file: { attributes: { playsInline: true, }, }, }}
                onEnded={onVideoEnd}
            />
        }
        {displayButton &&
        <div className='Start'>
            <div style={{position: 'relative', width: buttonWidth, height: buttonHeight, margin: "auto", top: "50%"}}>
                <ImageButton
                image='images/startbutton.png'
                    onClick={onEnd}
                />
        </div>
        </div>
        }
        </>
        );
    }
