import React from 'react';
import './BorderButton.css';

export interface BorderButtonProp  {
    onClick?: () => void;
}
export const BorderButton: React.FC<BorderButtonProp> = ({onClick}: BorderButtonProp) => {
    return ( 
        <button className='BorderButton' onClick={onClick}>
        </button>
    );
}