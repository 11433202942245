import React from 'react';
import './VerticallyAlignedButton.css';
import { VerticallyAlignedDivProps } from './VerticallyAlignedDiv';

interface VerticallyAlignedButtonProp extends VerticallyAlignedDivProps {
    onClick?: () => void;
}
export const VerticallyAlignedButton: React.FC<VerticallyAlignedButtonProp> = ({msg, onClick}: VerticallyAlignedButtonProp) => {
    return ( 
        <button className='VerticalAlignButton' onClick={onClick}>
            <p>
                {msg}
            </p>
        </button>
    );
}