import React, { useEffect, useState, useContext } from 'react';
import {VideoLoop} from "./VideoInterfaces";
import { BorderButton } from './Components/BorderButton';
import ReactPlayer from 'react-player'
import './Presentation.css';
import { SizeContext } from '../context';
import {buttons, videoLoop} from './Constants';
import { ImageButton } from './Components/ImageButton';
import { Form } from './Components/Form';
import { VerticallyAlignedButton } from './Components/VerticallyAllignedButton';

interface PresentationProps{
}

export const Presentation: React.FC<PresentationProps> = () => {
    const [displayAmbienceVideoIndex, setDisplayAmbienceVideoIndex] = useState(0);
    const [displayAmbienceVideo, setDisplayAmbienceVideo] = useState("ambience0.mp4");

    const [displayNextAmbienceVideoIndex, setDisplayNextAmbienceVideoIndex] = useState(1);
    const [displayNextAmbienceVideo, setDisplayNextAmbienceVideo] = useState("ambience1.mp4");

    const [currentAmbienceIndex, setCurrentAmbieceIndex] = useState(0);
    
    const [playAmbienceVideo, setPlayAmbienceVideo] = useState(true);
    const [playNextAmbienceVideo, setPlayNextAmbienceVideo] = useState(false);



    const [playVideo, setPlayVideo] = useState(false);
    const [playVideoName, setPlayVideoName] = useState("");
    const [playVideoIndex, setPlayVideoIndex] = useState(0);
    const [showVideo, setShowVideo] = useState(false);

    const [showButtons, setShowButtons] = useState(true);

    const [videoWidth, setVideoWidth] = useState('300px');
    const [videoHeight, setVideoHeight] = useState('71px');

    const [scaleWidth, setScaleWidth] = useState(10);
    const [scaleHeight, setScaleHeight] = useState(10);

    const [showForm, setShowForm] = useState(false);
    const [showFormButton, setShowFormButton] = useState(false);
    
    const context = useContext(SizeContext);

    const animationIsFinished = () => {
        setPlayVideo(true);
      }

      const onScreenresize = () => {
        var ratio = 2048.0 / 1080.0;

        var windowWidth = context.width;

        var widthLimit = 0.9 * windowWidth ;
        var heightLimit = widthLimit / ratio;

        setVideoHeight(heightLimit + 'px');
        setVideoWidth(widthLimit +'px');

        var imageHeight = 1080;
        var imageWidth = 2048;
        setScaleHeight(context.height / imageHeight);
        setScaleWidth(context.width / imageWidth);
      }


    useEffect(() => {
        window.addEventListener('animationend', animationIsFinished);
        window.addEventListener("resize", onScreenresize);
        window.addEventListener("orientationchange", onScreenresize);
        window.screen.orientation.addEventListener("change", onScreenresize);

        var windowWidth = context.width;

        var ratio = 2048.0 / 1080.0;

        var widthLimit = 0.9 * windowWidth ;
        var heightLimit = widthLimit / ratio;

        setVideoHeight(heightLimit + 'px');
        setVideoWidth(widthLimit +'px');

        var imageHeight = 1080;
        var imageWidth = 2048;
        setScaleHeight(context.height / imageHeight);
        setScaleWidth(context.width / imageWidth);

        setTimeout(() => {
            setShowFormButton(true);
        }, 15000);

        return () => {
            window.removeEventListener('animationend', animationIsFinished);
            window.removeEventListener('resize', onScreenresize);
            window.removeEventListener('orientationchange', onScreenresize);
            window.screen.orientation.removeEventListener('change', onScreenresize);
          };

    },[])

    

    const onAmbienceVideoEnd = () => {
        let loop = videoLoop.find((video:VideoLoop) => video.name === displayAmbienceVideo)?.loop ?? false;
        
        if (currentAmbienceIndex < displayAmbienceVideoIndex) {
            setCurrentAmbieceIndex(displayAmbienceVideoIndex);
        }
        
        if (!loop) {
            setPlayAmbienceVideo(false);
            setPlayNextAmbienceVideo(true);
        }
    }

    const onNextAmbienceVideoEnd = () => {
        var loop = videoLoop.find((video:VideoLoop) => video.name === displayNextAmbienceVideo)?.loop ?? false;

        if (currentAmbienceIndex < displayNextAmbienceVideoIndex) 
            setCurrentAmbieceIndex(displayNextAmbienceVideoIndex);
    }

    const onPlayVideo = (index: number) => {
        if (showVideo) {
            return;
        }
        var button = buttons[index];
        var video = button.video;
        setPlayVideoIndex(index);
        setPlayVideo(false);
        setPlayVideoName(video);
        setShowVideo(true);
        setShowButtons(false);
    }

    const onVideoEnd = () => {
        setShowVideo(false);
        setShowButtons(true);
        
        var button = buttons[playVideoIndex];
        var ambience = button.ambience;

        var count = ambience.length;
        if (count == 1) {
            var index = ambience[0];
            if (currentAmbienceIndex < index) {
                setPlayNextAmbienceVideo(false);
                setDisplayAmbienceVideoIndex(index);
                setCurrentAmbieceIndex(index);
                setDisplayAmbienceVideo("ambience" + index + ".mp4");
                setPlayAmbienceVideo(true);
            }
        } else if (count == 2) {
            var index = ambience[0];
            if (currentAmbienceIndex < index) {
                setPlayNextAmbienceVideo(false);
                setDisplayAmbienceVideoIndex(index);
                setCurrentAmbieceIndex(index);
                setDisplayAmbienceVideo("ambience" + index + ".mp4");
                setPlayAmbienceVideo(true);
                
                var nextIndex = ambience[1];
                setDisplayNextAmbienceVideoIndex(nextIndex);
                setDisplayNextAmbienceVideo("ambience" + nextIndex + ".mp4");
            } 
        }
    }

    const openForm = () => {
        var win = window.open("https://1gh4bw1ngyz.typeform.com/to/pZEdggDj", '_blank');
        win?.focus();

        setShowFormButton(false);
    }

    return (
        <>
        {/*<div className="AmbientVideo">*/}
        
        <ReactPlayer 
        style={{ display: playAmbienceVideo ? "block" : "none" }}
                    playing={playAmbienceVideo}
                    url={"videos/" + displayAmbienceVideo}
                    width='100%'
                    height='100%'
                    loop={videoLoop.find((video:VideoLoop) => video.name === displayAmbienceVideo)?.loop ?? false}
                    muted={showVideo}
                    pip={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onAmbienceVideoEnd}
                />
       
        <ReactPlayer 
        style={{ display: playNextAmbienceVideo ? "block" : "none" }}
                    playing={playNextAmbienceVideo}
                    url={"videos/" + displayNextAmbienceVideo}
                    width='100%'
                    height='100%'
                    loop={videoLoop.find((video:VideoLoop) => video.name === displayNextAmbienceVideo)?.loop ?? false}
                    muted={showVideo}
                    pip={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onNextAmbienceVideoEnd}
                />
        {/*</div>*/}

        {showVideo &&
        <div className='VideoBox'>
            <div className='VideoInnerBox' style={{width: videoWidth, height: videoHeight}}>
                <ReactPlayer 
                    playing={playVideo}
                    url={"videos/" + playVideoName}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    controls={true}
                    pip={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onVideoEnd}
                />

                <VerticallyAlignedButton msg = "x" onClick={onVideoEnd}/>
            </div>
        </div>
}
    
        {showButtons && buttons.map((button, index) => {
        return (
            <div key={index} style={{position: 'absolute', width: button.size.width * scaleWidth, height: button.size.height * scaleHeight, top: button.position.y * scaleHeight, left: button.position.x * scaleWidth}}>
            {button.enabledAfterAmbience < currentAmbienceIndex &&
            <BorderButton
                onClick={() => onPlayVideo(index)}
            />}
            </div> 
        );
      })}

        {showFormButton &&
        <div style={{position: 'absolute', width: 350 * scaleWidth, height: 99 * scaleHeight, bottom: 4 * scaleHeight, right: 48 * scaleWidth}}>
        <ImageButton
                    image='images/findmorebutton.png'
                        onClick={openForm}
                    />
        </div>
    }
        </>
    )
}