import {VideoLoop, Button} from "./VideoInterfaces";

export const videoLoop: Array<VideoLoop> = [
    {
        name: "ambience0.mp4",
        loop: false
    },
    {
        name: "ambience1.mp4",
        loop: true
    },
    {
        name: "ambience2.mp4",
        loop: true
    },
    {
        name: "ambience3.mp4",
        loop: true
    },
    {
        name: "ambience4.mp4",
        loop: true
    },
    {
        name: "ambience5.mp4",
        loop: true
    },
    {
        name: "ambience6.mp4",
        loop: false
    },
    {
        name: "ambience7.mp4",
        loop: true
    }
];

export const buttons: Array<Button> = [
    {
        position: {
            x: 404,
            y: 726
        }, 
        size: {
            width: 195,
            height: 56
        },
        video: "video1.mp4",
        ambience: [2],
        enabledAfterAmbience: -1
    },

    {
        position: {
            x: 681,
            y: 514
        }, 
        size: {
            width: 100,
            height: 43
        },
        video: "video2.mp4",
        ambience: [3],
        enabledAfterAmbience: 1
    },

    {
        position: {
            x: 1495,
            y: 727
        }, 
        size: {
            width: 171,
            height: 68
        },
        video: "video3.mp4",
        ambience: [4],
        enabledAfterAmbience: 2
    },

    {
        position: {
            x: 1303,
            y: 517
        }, 
        size: {
            width: 123,
            height: 49
        },
        video: "video4.mp4",
        ambience: [5],
        enabledAfterAmbience: 3
    },

    {
        position: {
            x: 837,
            y: 394
        }, 
        size: {
            width: 94,
            height: 38
        },
        video: "video5.mp4",
        ambience: [6, 7],
        enabledAfterAmbience: 4
    }
]